import React from 'react'
import { css } from '@emotion/core'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { BLACK, MAIN_FONT_FAMILY } from '../../../constants/theme.styles'

import { EmotionStyles } from '~/types/types'

const baseToggleContainerStyles = (checked: boolean, enabled: boolean, rounded: boolean) => css`
  position: relative;
  width: 5rem;
  height: 2.4rem;
  display: inline-block;
  background: ${checked ? BLACK : '#b6bfc2'};
  transition: 0.4s ease;

  ${rounded
    ? `
      border-radius: 15px;
      border: 1px solid ${checked ? BLACK : '#b6bfc2'};

      &:before {
        border-radius: 50%;
      }
    `
    : ''}
`

const baseToggleStyles = (checked: boolean, enabled: boolean) => css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s ease;

  &:before {
    width: 17px;
    height: 17px;
    position: absolute;
    border-radius: 50%;
    content: '';
    background: #f6f7fa none no-repeat 50% 50%;
    background-size: 50%;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    transition: 0.4s;
    cursor: pointer;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  }

  ${!enabled
    ? `
  `
    : ''}

  ${checked
    ? `
      &:before {
        left: calc(100% - 17px - 4px);
        background-image: url('${PUBLIC_ASSET_PREFIX}/static/icons/checkmark.svg');
        background-size: 50%;
      }
  `
    : ''}
`

const baseLabelStyles = (checked: boolean) => css`
  color: ${BLACK};
  font-family: ${MAIN_FONT_FAMILY};
  font-size: 16px;
  padding-right: 16px;
  flex: 1;
`

const hiddenCheckboxStyles = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  vertical-align: baseline;
`
const baseContainerStyles = css`
  width: 100%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean
  enabled?: boolean
  label?: string | React.ReactElement
  containerStyles?: EmotionStyles
  labelStyles?: EmotionStyles
  toggleStyles?: EmotionStyles
}

export const Toggle = ({
  checked = false,
  enabled = true,
  label,
  containerStyles,
  labelStyles,
  toggleStyles,
  ...rest
}: Props) => (
  <label css={[baseContainerStyles, containerStyles]}>
    <input checked={checked} type="checkbox" css={hiddenCheckboxStyles} {...rest} />
    {typeof label === 'string' ? (
      <div
        css={[baseLabelStyles(checked), labelStyles]}
        dangerouslySetInnerHTML={{
          __html: label.replace(
            '$1',
            checked ? 'VISIBLE' : '<span style="color: #929a9c;">HIDDEN</span>'
          )
        }}
      />
    ) : label ? (
      <div css={[baseLabelStyles(checked), labelStyles]}>{label}</div>
    ) : null}
    <div css={[baseToggleContainerStyles(checked, enabled, true), toggleStyles]}>
      <div css={baseToggleStyles(checked, enabled)} />
    </div>
  </label>
)

Toggle.displayName = 'Toggle'

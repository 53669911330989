import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Checkbox } from '../Checkbox'
import { Radio } from '../Radio'
import { Toggle } from '../Toggle'
import { CurrencyInput } from '../CurrencyInput'
import Icon from '../Icon'
import { GRAY_MEDIUM_DARK, BLUE } from '~/constants/theme.styles'
import { EmotionStyles } from '~/types/types'

export type IFilterItem = {
  type: 'checkbox' | 'radio' | 'dropdown' | 'toggle' | 'currency'
  label: string | React.ReactElement
  name: string
  value: string | string[] | boolean | number | undefined
  hidden?: boolean
  selected?: boolean
  properties?: {
    step?: string
  }
}

export type IFilterGroup = {
  label?: string | React.ReactElement
  name?: string
  collapsable?: boolean
  collapsed?: boolean
  standardFilterDropdown?: boolean
  items?: IFilterItem[]
  inputRef?: React.Ref<HTMLInputElement>
}

const filterLabelStyles = css`
  position: relative;
  color: #839399;
  font-family: Lato;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  display: block;
`

const standardFilterLabelStyles = css`
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 0;
`

const collapsableFilterLabelStyles = css`
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  .icon-inner-fill {
    fill: #839399 !important;
  }
  &:hover {
    color: ${GRAY_MEDIUM_DARK};
    .icon-inner-fill {
      fill: ${GRAY_MEDIUM_DARK} !important;
    }
  }
`

const filterItemListStyles = css`
  padding: 0;
`

const standardFilterItemListStyles = css`
  padding-top: 5px;
  padding-bottom: 10px;
`

const collapsedFilterItemListStyles = css`
  display: none;
`

const filterItemStyles = css`
  padding: 5px 0;
`
const standardFilterItemStyles = css`
  padding: 0;
`

const hiddenFilterItemStyles = css`
  display: none;
`

const filterContainerStyles = css`
  margin-top: 10px;
  user-select: none;
  padding: 10px 15px;
  border-radius: 10px;
  background: #f6f7fa;
  &:first-of-type {
    margin-top: 0;
  }
`
const standardFilterContainerStyles = css`
  padding: 0;
  background: #ffffff;
`

const iconStyles = css`
  font-size: 14px;
`

type Props = {
  labelCss?: EmotionStyles
  itemLabelCss?: EmotionStyles
  customCss?: EmotionStyles
  styledIconStyles?: EmotionStyles
  onUpdate?: (
    type: string,
    name: string,
    value: string | number | boolean | string[] | undefined,
    selected?: boolean
  ) => void
} & IFilterGroup

export const FilterGroup: React.FC<Props> = ({
  name,
  label,
  items,
  collapsed: initialCollapsed = false,
  collapsable = false,
  standardFilterDropdown = false,
  labelCss,
  itemLabelCss,
  customCss,
  styledIconStyles,
  onUpdate,
  inputRef,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed)

  return (
    <div
      css={[
        filterContainerStyles,
        standardFilterDropdown ? standardFilterContainerStyles : null,
        customCss ? customCss : null
      ]}
      {...rest}
    >
      {label ? (
        <div
          css={[
            filterLabelStyles,
            standardFilterDropdown ? standardFilterLabelStyles : null,
            collapsable ? collapsableFilterLabelStyles : null,
            labelCss
          ]}
          onClick={() => {
            setCollapsed(!collapsed)
          }}
        >
          {label}
          {collapsable ? (
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
              `}
            >
              <Icon icon="chevron" rotate={collapsed ? 0 : 180} css={iconStyles} />
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        css={[
          filterItemListStyles,
          standardFilterDropdown ? standardFilterItemListStyles : null,
          collapsable && collapsed ? collapsedFilterItemListStyles : null
        ]}
      >
        {items !== undefined
          ? items.map((item, index) => (
              <div
                data-cy={`collapsable-${item.name}`}
                key={index}
                css={[
                  filterItemStyles,
                  standardFilterDropdown ? standardFilterItemStyles : null,
                  item.hidden ? hiddenFilterItemStyles : null
                ]}
              >
                {item.type === 'radio' ? (
                  <Radio
                    inputRef={inputRef}
                    label={item.label}
                    name={item.name}
                    value={item.value ? item.value.toString() : ''}
                    checked={Boolean(item.selected)}
                    styledIconStyles={styledIconStyles}
                    containerStyles={[
                      standardFilterDropdown
                        ? css`
                            border-left: 4px solid transparent;
                            padding: 9px 15px;
                            :hover {
                              background-color: rgb(243, 243, 243);
                              border-color: ${BLUE};
                            }
                          `
                        : null
                    ]}
                    labelStyles={[
                      css`
                        color: #0e1111;
                        font-family: Lato, sans-serif;
                        font-size: 14px;
                        padding-left: 12px;
                        letter-spacing: 0.3px;
                      `,
                      itemLabelCss
                    ]}
                    onChange={e => {
                      onUpdate?.(item.type, item.name, item.value, e.currentTarget.checked)
                    }}
                    {...item.properties}
                  />
                ) : null}
                {item.type === 'checkbox' ? (
                  <Checkbox
                    inputRef={inputRef}
                    label={item.label}
                    name={item.name}
                    checked={Boolean(item.selected)}
                    containerStyles={[
                      standardFilterDropdown
                        ? css`
                            border-left: 4px solid transparent;
                            padding: 9px 15px;
                            :hover {
                              background-color: rgb(243, 243, 243);
                              border-color: ${BLUE};
                            }
                          `
                        : null
                    ]}
                    labelStyles={[
                      css`
                        color: #0e1111;
                        font-family: Lato, sans-serif;
                        font-size: 14px;
                        padding-left: 12px;
                        letter-spacing: 0.3px;
                      `,
                      itemLabelCss
                    ]}
                    onChange={e => {
                      onUpdate?.(item.type, item.name, item.value, e.currentTarget.checked)
                    }}
                    {...item.properties}
                  />
                ) : null}
                {item.type === 'currency' ? (
                  <CurrencyInput
                    label={item.label}
                    name={item.name}
                    value={Number(item.value)}
                    onChange={e => {
                      onUpdate?.(item.type, item.name, e.currentTarget.value)
                    }}
                    {...item.properties}
                  />
                ) : null}
                {item.type === 'toggle' ? (
                  <Toggle
                    label={item.label}
                    name={item.name}
                    checked={Boolean(item.selected)}
                    labelStyles={itemLabelCss}
                    onChange={e => {
                      onUpdate?.(item.type, item.name, item.value, e.currentTarget.checked)
                    }}
                    {...item.properties}
                  />
                ) : null}
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

FilterGroup.displayName = 'FilterGroup'

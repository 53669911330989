import React from 'react'
import Input from '../Input'
import { css } from '@emotion/core'

type Props = {
  name?: string
  label?: string | React.ReactElement
  value?: number
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
}

export const CurrencyInput: React.FC<Props> = ({ value, label, onChange, ...rest }) => {
  const handleKeywordChange = (event: React.FormEvent<HTMLInputElement>): void => {
    onChange?.(event)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const keyCode = event.which || event.keyCode

    if (keyCode === 187 || keyCode === 189) event.preventDefault()
  }

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {label ? (
        <div
          css={css`
            flex: 1;
            width: 50%;
            color: #0e1111;
            font-family: 'Lato', 'Lucida Grande', sans-serif;
            font-size: 14px;
            font-weight: 400;
            padding-right: 16px;
            align-self: center;
          `}
        >
          {label}
        </div>
      ) : null}
      <Input
        aria-label="Currency"
        wrapperCss={css`
          width: 50%;
        `}
        inputSize="large"
        placeholder="0.00"
        value={value ? value : 0}
        icon="dollar"
        iconPosition="left"
        type="number"
        pattern="^-?[0-9]\d*\.?\d*$"
        onChange={handleKeywordChange}
        onKeyDown={handleKeyDown}
        {...rest}
      />
    </div>
  )
}

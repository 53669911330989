import { css } from '@emotion/core'
import React from 'react'
import { EmotionStyles } from '~/types/types'
import { BLACK, MAIN_FONT_FAMILY, WHITE } from '../../../constants/theme.styles'

const iconStyles = css`
  display: none;
  fill: none;
  stroke: ${WHITE};
  stroke-width: 4px;
  max-width: 12px;
  max-height: 12px;
  margin: 2px 0 0 2px;
`
const iconContainerStyles = css`
  display: inline-block;
  overflow: hidden;
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #b6bfc2;
  transition: all 150ms;
  background: ${WHITE};
`

const StyledCheckboxIcon: React.FC<{
  customCss?: EmotionStyles
}> = ({ customCss }) => {
  return (
    <div className="uiStyledRadioIcon" css={[iconContainerStyles, customCss ? customCss : null]}>
      <svg viewBox="0 0 24 24" css={iconStyles}>
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </div>
  )
}

const hiddenCheckboxStyles = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  vertical-align: baseline;
  :checked + div {
    border-color: ${BLACK};
    background: ${BLACK};
    svg {
      display: block;
    }
  }
`

const baseLabelStyles = css`
  color: ${BLACK};
  font-family: ${MAIN_FONT_FAMILY};
  font-size: 16px;
  font-weight: 400;
  padding-left: 16px;
  line-height: 1em;
`
const baseContainerStyles = css`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  label?: string | React.ReactElement
  containerStyles?: EmotionStyles
  labelStyles?: EmotionStyles
  iconCss?: EmotionStyles
  inputRef?: React.Ref<HTMLInputElement>
  value?: string
  styledIconStyles?: EmotionStyles
}

export const Radio = ({
  checked,
  label,
  containerStyles,
  labelStyles,
  iconCss,
  inputRef,
  styledIconStyles,
  ...rest
}: Props) => {
  return (
    <label css={[baseContainerStyles, containerStyles]}>
      <div
        css={css`
          height: 18px;
        `}
      >
        <input checked={checked} type="radio" css={hiddenCheckboxStyles} ref={inputRef} {...rest} />
        <StyledCheckboxIcon customCss={[styledIconStyles, iconCss]} />
      </div>
      {typeof label === 'string' ? (
        <div css={[baseLabelStyles, labelStyles]} dangerouslySetInnerHTML={{ __html: label }} />
      ) : (
        <div css={[baseLabelStyles, labelStyles]}>{label}</div>
      )}
    </label>
  )
}

Radio.displayName = 'Radio'

export default Radio
